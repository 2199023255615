import axiosHttp from './axiosHttp'
export default {
    async getByModuleNavId(id) {
        try {
            const url = '/moduleAcl/nav/' + id
            const result = await axiosHttp.get(url)
            if (result && result.data) {
                return result.data
            }
        } catch (error) {
            console.log(error.response.data)
        }
    },

    async getAllModuleNav() {
        try {
            const url = '/moduleAcl/navs'
            const result = await axiosHttp.get(url)
            if (result && result.data) {
                return result.data
            }
        } catch (error) {
            console.log(error.response.data)
        }
    },

    async getAllRolesByModuleId(moduleId) {
        try {
            const url = '/moduleAcl/getAllRolesByModuleId/' + moduleId
            const result = await axiosHttp.get(url)
            if (result && result.data) {
                return result.data
            }
        } catch (error) {
            console.log(error.response.data)
        }
    },

    async getRoleById(id) {
        try {
            const url = '/moduleAcl/getModuleRoleById/' + id
            const result = await axiosHttp.get(url)
            if (result && result.data) {
                return result.data
            }
        } catch (error) {
            console.log(error.response.data)
        }
    },

    async getAllUsersByModuleRoleId(moduleRoleId) {
        try {
            const url = '/moduleAcl/getAllUsersByModuleRoleId/' + moduleRoleId
            const result = await axiosHttp.get(url)
            if (result && result.data) {
                return result.data
            }
        } catch (error) {
            console.log(error.response.data)
        }
    },

    async updateModuleRole(moduleRole) {
        try {
            const url = '/moduleAcl/updateModuleRole'
            const result = await axiosHttp.post(url, moduleRole)
            return result
        } catch (error) {
            console.log(error)
            return error.response.data
        }
    },

    async updateUserRole(moduleUserRole) {
        try {
            const url = '/moduleAcl/updateUserRole'
            const result = await axiosHttp.post(url, moduleUserRole)
            return result
        } catch (error) {
            console.log(error)
            return error.response.data
        }
    },

    async getSessionMemberNavs() {
        try {
            const url = '/moduleAcl/getSessionMemberNavs/'
            const result = await axiosHttp.get(url)
            if (result && result.data) {
                return result.data
            }
        } catch (error) {
            console.log(error.response.data)
        }
    }
}