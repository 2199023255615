import ModuleNavAclApi from "../api/ModuleNavAclApi"
import {
    MODULE_ROLE_ALLOW_ALL, MODULE_ROLE_ALLOW_OVERRIDE,
    USER_TYPE_MANAGER,
    USER_TYPE_RECEIPTS
} from "../constants/userConstants"

export default {
    hasModuleRole(sessionMember) {
        if (_.includes([USER_TYPE_RECEIPTS, USER_TYPE_MANAGER], sessionMember?.typeId)) {
            return true
        } else{
            return false
        }
    },

    async getMemberAclList() {
        const navsResults = await ModuleNavAclApi.getSessionMemberNavs()
        const navs = []
        _.forEach(navsResults, (nav) => {
            if (nav?.ModuleNav?.navIds) {
                const navIds = JSON.parse(nav?.ModuleNav?.navIds)
                _.forEach(navIds, (navId) => {
                    const data = {
                        status: nav.status,
                        navId: navId
                    }
                    navs.push(data)
                })
            }
        })
        return navs
    },

    checkMemberPathAcl(sessionUser, path, memberAcls) {
        let allow = false
        if (_.includes([USER_TYPE_RECEIPTS, USER_TYPE_MANAGER], sessionUser?.typeId)) {
            if (memberAcls) {
                _.forEach(memberAcls, memberAcl => {
                    if (memberAcl.navId === path) {
                        if (memberAcl.status === MODULE_ROLE_ALLOW_ALL) {
                            allow = true
                        } else if (memberAcl.status === MODULE_ROLE_ALLOW_OVERRIDE) {
                            allow = true
                        }
                    }
                })
            }
        }
        return allow
    }
}