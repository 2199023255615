import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined"

const ReceptionistMenu = {
    id: 'receptionistZoneRoot',
    title: 'RECEPTIONIST ZONE',
    type: 'group',
    children: [
        {
            id: 'cashAccounting',
            title: 'Cash Accounting',
            type: 'item',
            url: '/cashAccounting',
            icon: CurrencyExchangeOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'cardAccounting',
            title: 'Card Accounting',
            type: 'item',
            url: '/cardAccounting',
            icon: CurrencyExchangeOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'insuranceAccounting',
            title: 'Insurance Accounting',
            type: 'item',
            url: '/insuranceAccounting',
            icon: CurrencyExchangeOutlinedIcon,
            breadcrumbs: false
        }
    ]
}
export default ReceptionistMenu